@import "../variable";

.export-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 10;

    .inner-container {
        z-index: 10;
    }
    .larr{
        font-size: 1.5rem;
    }
    .mt-32{
        margin-top: 32px;
    }
    .heading {
        // margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.1rem;
        font-weight: 800;
        display: flex;
        align-items: center;
    }
    
    .export-heading{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .key{
        font-size: 0.8rem;
        margin-bottom: 1rem;
        margin-top: 0.1rem;
    }

    .form {
        word-break: break-all;
        width: 80%;
        margin: auto;
        margin-top: 10px;
        background-color: transparent !important;
        padding: 1rem;
        border-radius: 15px;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 /20%);
        // color: #fff;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 1rem;
        border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
        color: #fff;
        label {
            font-size: 0.9rem;
        }
        
        form {
            margin-top: 10px;

            input {
                border-radius: 0.3rem;
                outline: none;
                // border: 1px solid $button;
                padding: 3px 8px;
                width: 90%;

                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);
                padding: 0.5rem;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
                color: #fff;
                &::placeholder{
                    color: #fff;

                }
            }

            .checkbox {
                font-size: 0.7rem;
                vertical-align: middle;
                margin-top: 10px;

                input {
                    width: auto;
                    vertical-align: middle;
                }

                span {
                    color: blue;
                }
            }
        }
    }

    .btn {
        text-align: center;

        button {
            width: 100%;
            margin-top: 5px;
            border: none;
            padding: 9px 10px;
            background-color: $button;
            color: #fff;
            font-weight: 600;
            border-radius: 0.3rem;
            font-size: 0.7rem;
            text-align: center;
            vertical-align: middle;
        }
    }

    .items {
        background: transparent;
        color: aliceblue;
        border: none;
    }
    .items:focus {
        box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
        border: none;
    }

    .normal-text {
        font-size: 0.8rem;
        text-align: justify;
        margin-top: 5px;
        word-wrap: break-word;
    }
}