@import "../variable";

#send {
    // background-color: #1c1e29;
    background: linear-gradient(to top, #160c54, #4e10a0);
    color: #fff;
    width: 100vw;
    // height: 100vh;
    padding: .5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    

    .container {
        // background-color: #282a36;
        // background-color: #fff;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 1rem;
        padding-top: .5rem;
        border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
        position: relative;
        // margin: 1rem;
        // overflow-x: hidden;
        button {
            margin-top: 0px;
            border: 1px solid #fff;
            padding: 7px 10px;
            background-color: transparent;
            color: #fff;
            font-weight: 600;
            font-size: 0.7rem;
            // text-align: center;
            // vertical-align: middle;
        }
        
        .items {
            background: transparent;
            color: aliceblue;
            border: none;
        }
        .items:focus {
            box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
            border: none;
        }
    

        // padding-top: 2rem;
        .larr {
            font-size: 1.3rem;
        }

        .first {
            display: flex;
            align-items: center;
            gap: 10px;

            .code {
                font-size: 2rem;
                margin-left: 1rem;
            }

            h1 {
                font-size: 1.5rem;
            }
            
        }

        .second {
            h2 {
                margin-bottom: .5rem;
                font-size: 1rem;
            }

            .data {
                display: flex;
                justify-content: space-between;
                // padding: 0.2rem;
                // width: 100%;
                // height: 25px;
                padding-right: 0.2rem;
                border-radius: .2rem;
                border: none;
                // border: 1px solid #01aff7;
                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);
                align-items: center;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.659);
                    padding-left: .5rem;
                }

                input {
                    width: 100%;
                    height: 25px;
                    border-radius: .2rem;
                    border: none;
                    background: transparent;
                    // backdrop-filter: blur(5px);
                    // -webkit-backdrop-filter: blur(5px);
                    color: #fff;

                    &::placeholder {
                        color: rgba(255, 255, 255, 0.659);
                        padding-left: .5rem;
                    }

                }
            }
        }
        .items {
            background: transparent;
            color: aliceblue;
            border: none;
        }
        .items:focus {
            box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
        }
        .third {
            h2 {
                margin-bottom: .5rem;
                margin-top: 1rem;
                font-size: 1rem;
            }

            input {
                width: 100%;
                height: 25px;
                border-radius: .2rem;
                border: none;
                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);
                color: #fff;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.659);
                    padding-left: .5rem;
                }

            }

            .error {
                border-color: red;
            }

            .amount-error {
                color: red;
                margin-top: 4px;
                font-size: 0.8rem;
            }
        }

        .forth {
            display: flex;
            justify-content: center;
            flex-direction: column;

            h2 {
                margin-bottom: .5rem;
                margin-top: .5rem;
                font-size: 1rem;
                font-weight: 400;
                text-align: center;
            }

            p {
                text-align: center;
            }

            button {
                // padding: .3rem 4rem;
                margin-top: .5rem;
                background-color: $button;
                border: 1px solid #fff;
                border-radius: .3rem;
                color: #fff;
                
            }
        }
    }
}

.inner-container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .heading {
        // margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.1rem;
        font-weight: 800;
        display: flex;
        align-items: center;
    }
    .export-heading{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .key{
        font-size: 0.8rem;
        margin-bottom: 1rem;
        margin-top: 0.1rem;
    }

    .form {
        word-break: break-all;
        width: 80%;
        margin-top: 10px;
        background-color: transparent !important;
        padding: 1rem;
        border-radius: 15px;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 /20%);
        // color: #fff;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 1rem;
        border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
        color: #fff;
    }
}

.scanner-div {
    background: linear-gradient(to top, #160c54, #4e10a0);
    color: #fff;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;

    .container {
        // background-color: #282a36;
        // background-color: #fff;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 0.5rem;
        border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
        position: relative;
        padding-top: 1rem;
        margin: 1rem;

        .larr {
            font-size: 1.5rem;
        }
    }

    .heading-scanner {
        
        display: flex;

        h3 {
            display: inline-block;
            margin-left: 10px;
        }
    }

    .camera{
        margin: 8px 0px;
    }
}

.btn{
    text-align: center;
    z-index: 10;
    margin-top: 8px;
    
    button{
        width: 100%;
        border: none;
        padding: 7px 10px;
        background-color: $button;
        color: #fff;
        font-weight: 600;
        border-radius: 0.3rem;
        font-size: 0.7rem;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #fff;
    }
}

.camera video{
    width: 100%;
    height: 55vh;
}

.export-pop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 10;

    .inner-container {
        z-index: 10;
    }
    .larr{
        font-size: 1.5rem;
    }
    .mt-32{
        margin-top: 32px;
    }
    .heading {
        // margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1rem;
        font-weight: 800;
        display: flex;
        align-items: center;
    }
    .export-heading{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .key{
        font-size: 0.8rem;
        margin-bottom: 1rem;
        margin-top: 0.1rem;
    }

    .form {
        word-break: break-all;
        width: 80%;
        margin: auto;
        margin-top: 10px;
        background-color: transparent !important;
        padding: 1rem;
        border-radius: 15px;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 /20%);
        // color: #fff;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 1rem;
        border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
        color: #fff;
        
        button {
            margin-top: 0px;
            border: 1px solid #fff;
            background-color: transparent;
            color: #fff;
            font-weight: 600;
            font-size: 0.7rem;
            // text-align: center;
            // vertical-align: middle;
        }
        
        .items {
            background: transparent;
            color: aliceblue;
            // border: none;
        }
        .items:focus {
            box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
            border: none;
        }
        label {
            font-size: 0.9rem;
        }
        
        form {
            margin-top: 10px;

            input {
                border-radius: 0.3rem;
                outline: none;
                margin-top: 10px;
                padding: 3px 8px;
                width: 90%;

                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);
                padding: 0.5rem;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
                color: #fff;
                &::placeholder{
                    color: #fff;

                }
            }

            .checkbox {
                font-size: 0.7rem;
                vertical-align: middle;
                margin-top: 10px;

                input {
                    width: auto;
                    vertical-align: middle;
                }

                span {
                    color: blue;
                }
            }
        }
    }

    .btn {
        text-align: center;

        button {
            width: 100%;
            margin-top: 10px;
            border: 1px solid #fff;
            padding: 7px 10px;
            background-color: $button;
            color: #fff;
            font-weight: 600;
            border-radius: 0.3rem;
            font-size: 0.7rem;
            text-align: center;
            vertical-align: middle;
        }
    }

    .items:focus {
        box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
    }

    .normal-text {
        font-size: 10px;
        text-align: center;
        margin-top: 5px;
    }
}