@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
.App{
  background: linear-gradient(to top , #160c54, #4e10a0);
  height: 100vh;
  font-family: 'Ubuntu', sans-serif;
  /* background: url('https://cdn.dribbble.com/users/1175433/screenshots/4774659/crypto_dribble.png?compress=1&resize=400x300&vertical=top') no-repeat; */
  /* background: url('https://cdn.dribbble.com/users/1314508/screenshots/6525918/artboard_4x.jpg') no-repeat; */
  /* background: url('https://cdn.dribbble.com/users/610636/screenshots/7066345/media/d4f5bbfa99d04500a95d62329419e301.png?compress=1&resize=400x300&vertical=top') no-repeat; */
  /* background: url('https://cdn.dribbble.com/users/2205487/screenshots/5341433/crypto-currency-isometric_4x.png') no-repeat; */
  background-size: 100vw 100vh;
  overflow: scroll;
}

* {
  margin: 0;
  padding: 0;
}
.wrapper {
  height: 100%;
  width: 100%;
  background: transparent;
  position: absolute;
  z-index: 5;
  overflow: hidden;
}
 .wrapper div {
  height: 60px;
  width: 60px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: 4s linear infinite;
}
 div .dot {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 20%;
  right: 20%;
} 
/* .items{
  border: 5px solid red;
} */

/* .items:focus-visible{
  border: 10px solid red !important;
} */
 .wrapper div:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: animate 3s linear infinite;
}
.wrapper div:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: animate 5s linear infinite;
}
.wrapper div:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(8) {
  top: 75%;
  left: 60%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: animate 9s linear infinite;
}
@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }
  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
} 