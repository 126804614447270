@import "../variable";


.wallet-container {
    .header-top {
        // display: flex;
        // justify-content: space-between;
        float: right;
        padding-top: 5px;
        padding-right: 9px;
        color: #fff;
        .items {
            background: #4e10a0;
            color: aliceblue;
            border: none;
        }
        .items:focus {
            box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
            
        }
    }

    .wallet-header {
        // background: linear-gradient(to top, #160c54, #4e10a0);
        color: #fff;
        height: 130px;
        position: absolute;
        top: 0;

        // This is for center 
        height: 91vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .wallet-balance {
            font-size: 1rem;
            display: flex;
            align-items: center;
            img{
                width: 23px;
            }
        }

        .font-1_5 {
            font-size: 1.5rem !important;
        }

        .font-1_4 {
            font-size: 1.4rem !important;
        }

        .font-1_3 {
            font-size: 1.3rem !important;
        }

        .font-1_2 {
            font-size: 1.2rem !important;
        }

        .font-1_1 {
            font-size: 1.1rem !important;
        }

        .content {
            font-size: 1.3rem;
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            word-wrap: break-word;

            p {
                font-size: 0.9rem;
                word-wrap: break-word;
                width: 190px;
                text-align: center;
            }
            .items {
                background: transparent;
                color: aliceblue;
                border: none;
            }
            .items:focus {
                box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
                border: none;
            }
        }

        .wallet-function {
            display: flex;
            justify-content: center;

            .functions {
                margin: 10px;
                font-size: 0.7rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                button{
                    background: linear-gradient(to top, #4e10a0, #4e10a0);
                    // background: #fff;
                    color: #fff;
                    display: flex;
                    font-size: 1.2rem;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    width: 31px;
                    height: 31px;
                    margin-bottom: 5px;
                    border: none;
                    
                    .dicon{
                        margin: 0px 0px 0px 4px;
                    }
                }

                .items:focus {
                    box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
                }
            }
        }
    }

    .wallet-body {
        margin: 0rem .7rem;
        margin-top: 55%;
        // background-color: #fff;
        height: 56vh;
        overflow: hidden;
        padding-top: 8px;
        border-radius: 8px 8px 0 0;
        position: relative;

        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        // padding: 1rem;
        // border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
        color: #fff;

        .body-heading {
            text-align: center;
            text-decoration: underline;
            color: #fff;
            padding-bottom: 7px;
        }

        .all-transaction {
            overflow-y: scroll;
            height: 100%;
            padding-bottom: 10px;
            color: #fff;

            .trans.last {
                margin-bottom: 18px;
            }

            .trans {
                display: flex;
                align-items: center;
                font-size: 0.8rem;
                // margin-top: 10px;
                border-top: 1px solid #ddd;
                border-radius: 5px;
                padding: 5px;
                color: #160c54;
                color: #fff;


                .trns-icon {
                    font-size: 1.2rem;
                    margin-right: 10px;
                    height: 31px;
                    width: 31px;
                    // background-color: #160c54;
                    border-radius: 50%;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background: rgba(255, 255, 255, 0.05);
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    // padding: 1rem;
                    // border-radius: .5rem;
                    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);

                }

                .trans-point {
                    display: inline-block;
                }
            }
        }

        .no-transaction {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                width: 72%;
            }

            div {
                color: #fff;
            }
        }

    }

}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

@media screen and (min-width:800px) {
    .wallet-container .wallet-body {
        margin-top: 15%;
        height: 70vh;
    }

    .wallet-body {}

    .no-transaction {
        img {
            width: 28% !important;
        }
    }

}