#details {
    // background-color: #1c1e29;
    background: linear-gradient(to top, #160c54, #4e10a0);
    color: #fff;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 15px;

    .container {
        width: 80%;
        // background-color: #282a36;
        // background-color: #fff;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 1rem;
        border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);

        .first {
            display: flex;
            // justify-content: space-between;
            gap: 8px;
            align-items: center;

            .items {
                background: transparent;
                color: aliceblue;
                border: none;
            }

            .items:focus {
                box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
                border: none;
            }

            .code {
                font-size: 2rem;
                margin-left: 1rem;
            }

            h1 {
                font-size: 1.5rem;
            }
        }

        .second {
            h2 {
                margin-bottom: .5rem;
                margin-top: 1rem;
                font-size: 1rem;
            }

            .data {
                display: flex;
                justify-content: space-between;
                padding: 0.2rem;
                // width: 100%;
                // height: 25px;
                border-radius: .2rem;
                border: none;
                // border: 1px solid #01aff7;
                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);

                &::placeholder {
                    color: rgba(255, 255, 255, 0.659);
                    padding-left: .5rem;
                }

                span {
                    word-wrap: break-word;
                    width: 155px;
                }
            }
        }

        .third {
            h2 {
                margin-bottom: .5rem;
                margin-top: 1rem;
                font-size: 1rem;
            }

            input {
                width: 100%;
                height: 25px;
                border-radius: .2rem;
                border: none;
                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);

                &::placeholder {
                    color: rgba(255, 255, 255, 0.659);
                    padding-left: .5rem;
                }
            }

        }

        .forth {
            display: flex;
            justify-content: center;
            flex-direction: column;

            h2 {
                margin-bottom: .5rem;
                margin-top: 1rem;
                font-size: 1rem;
                font-weight: 400;
                text-align: center;
            }

            p {
                text-align: center;
            }

            button {
                padding: .3rem 4rem;
                margin-top: .5rem;
                background-color: #01aff7;
                border: none;
                outline: none;
                border-radius: .3rem;
                color: #fff;
            }
        }
    }
}