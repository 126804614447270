.transaction-body {
    margin: 0rem .7rem;
    margin-top: 20px;
    // background-color: #fff;
    // height: 56vh;
    overflow: hidden;
    padding-top: 8px;
    border-radius: 8px 8px 0 0;
    position: relative;

    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    // padding: 1rem;
    border-radius: .5rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
    color: #fff;

    .transaction-section {
        height: 78vh;
        padding: 10px;
    }

    .notransaction-section {
        padding: 15px;
        height: 56vh;
    }
    .mb-4{
        margin-bottom: 4px;
    }

    .body-heading {
        // text-decoration: underline;
        color: #fff;
        padding-bottom: 7px;
        button {
            margin-top: 2px;
            margin-left: 10px;
            border: none;
            padding: 7px 10px;
            background-color: transparent;
            color: #fff;
            font-weight: 600;
            font-size: .7rem;
            text-align: center;
            vertical-align: middle;
            width: 33px;
        }
    }

    .items {
        background: transparent;
        color: aliceblue;
        border: none;
    }

    .items:focus {
        box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
        border: none;
    }

}

.heading {
    .items {
        background: transparent;
        color: aliceblue;
        border: none;
    }

    .items:focus {
        box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
        border: none;
    }
}

.all-transaction {
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 10px;
    color: #fff;

    .trans.last {
        margin-bottom: 18px;
    }

    // .trans:last-child{
    //     border: unset;
    // }

    .trans {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        // margin-top: 10px;
        border-top: 1px solid #ddd;
        // border-radius: 5px;
        padding: 5px;
        color: #160c54;
        color: #fff;

        button {
            margin-top: 0px;
            border: none;
            padding: 7px 10px;
            background-color: transparent;
            color: #fff;
            font-weight: 600;
            font-size: 0.7rem;
        }

        .wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .items {
            background: transparent;
            color: aliceblue;
            display: flex;
        }

        .trans-sec {
            text-align: left;
        }

        .items:focus {
            box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
            border: 1px solid rgba(48, 124, 248, 0.993);
        }

        .trns-icon {
            font-size: 1.2rem;
            margin-right: 10px;
            height: 31px;
            width: 31px;
            // background-color: #160c54;
            border-radius: 50%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            background: rgba(255, 255, 255, 0.05);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            // padding: 1rem;
            // border-radius: .5rem;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);

        }

        .trans-point {
            display: inline-block;
        }
    }
}

.no-transaction {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 72%;
    }

    div {
        color: #fff;
    }
}