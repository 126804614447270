@import "../variable";
.s{
    // display: flex;
    // height: 100vh;
    // justify-content: center;
    // align-items: center;
}
.setting-page {
    color: #fff;
    padding: 0;

    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: .5rem;
    border-radius: .5rem;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
    color: #fff;
    margin: .5rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    .heading {
        padding: 0.5rem;
        vertical-align: middle;
        // border-bottom: 1px solid #ddd;
        font-size: 1rem;
        display: flex;
        gap: 8px;
        align-items: center;
        .items {
            background: transparent;
            color: aliceblue;
        }
        .items:focus {
            box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
            border: none;
        }
    }

    .option {
        // border-bottom: 1px solid #ddd;
        padding: 0.5rem;
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 0.9rem;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: .5rem;
        border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    .items {
        background: transparent;
        color: aliceblue;
        border: none;
    }
    .items:focus {
        box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
        border: none;
    }

}