@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

@import "../variable";

.home-card-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
}
.home-card-container{
  width: 80%;
  margin: auto;
  // background-color: #fff;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem;
  border-radius: .5rem;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
  color: #fff;

  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 /20%);
  // position: absolute;
  // top: 20px;
  left: 0;
  right: 0;
  z-index: 10;
  .home-card-container-heading{
    text-align: center;
    // color: #000;
    font-size: 1.1rem;
    font-weight: bolder;
    line-height: 25px;
    z-index: 10;
    color: #fff;

    span{
        color: $button;
      }
  }
  .home-card-container-p{
    font-size: 0.8rem;
    color: grey;
    margin: 13px auto;
    z-index: 10;
    text-align: center;
    color: #fff;

  }
  .home-btn{
    text-align: center;
    z-index: 10;
    // width: 100px;

    button{
        border: none;
        width: 100%;
        padding: 7px 10px;
        background-color: $button;
        color: #fff;
        font-weight: 600;
        border-radius: 0.3rem;
        font-size: 0.7rem;
      }
      .items:focus{
        box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
          }
     
  } 
  
}
