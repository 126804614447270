@import "../variable";
.verification-mnmonic-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
    .question{
        font-size: 0.9rem;
        margin-bottom: 5px;
        margin-right: 5px;
    }
    
    .inner-container {
        z-index: 10;
        width: 85%;
        margin: auto;
        // margin-top: 10px;
        background-color: #fff;
        // padding: 1rem;
        padding: 10px;
        border-radius: 15px;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 /20%);

        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        // padding: 1rem;
        // border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
        color: #fff;
        .ans{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            margin-top: 10px;
            font-size: 0.8rem;
        }
        .pass{
            margin-top: 10px;
            font-size: 0.8rem;
            label{
                font-weight: 800;
                margin-bottom: 5px;
            }
            .input{
                border-radius: 0.3rem;
                outline: none;
                border: 1px solid $button;
                padding: 3px 8px;
                margin: auto;
                width: 88%;
                margin-top: 5px;
                
                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);
                // padding: 1rem;
                // border-radius: .5rem;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
                color: #fff;
            }
        }
        .pascode-heading{
            font-size: 0.9rem;
        }
        .passcode-p{
            margin-top: 8px;
            font-size: 0.75rem;
            color: #fff;

        }
        ul{
            padding: 0;
            margin-bottom: 0;
            margin-top: 0;
            li{
                list-style: none;
                background-color: $button;
                color: #fff;
                display: inline-block;
                width: 38.5%;
                border-radius: 15px;
                padding: 5px 0px;
                padding-left: .7rem;
                margin: 5px 5px;
                // text-align: center;
                font-size: 0.8rem;
                
                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);
                // padding: 1rem;
                // border-radius: .5rem;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
                color: #fff;
            }
            // li.error{
            //     background-color: red;
            // }
            // li.success{
            //     background-color: green;
            // }
            li.init{
                background-color: #6c5ce7;
            }
        }
        input.enter{
                list-style: none;
                background-color: transparent;
                color: #fff;
                display: inline-block;
                width: 43%;
                border-radius: 15px;
                text-align: center;
                font-size: 0.8rem;
                border: none;
                outline: none;
                
                
        }
        
        .btn{
            text-align: center;
            width: 100%;
            button{
                width: 100%;
                border: none;
                padding: 7px 10px;
                background-color: $button;
                color: #fff;
                font-weight: 600;
                border-radius: 0.3rem;
                font-size: 0.7rem;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
    .items:focus{
        // box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
          }
}