@import "../variable";
.import-wallet-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 10;
    .inner-container{
        z-index: 10;
    }
    .heading{
        margin-top: 10px;
        text-align: center;
        font-size: 1rem;
        text-decoration: underline;
        font-weight: 800;
    }
    .sub-heading{
        font-size: 0.8rem;
        margin-top: 8px;
    }
    .or{
        text-align: center;
        font-size: 0.8rem;
        font-weight: 600;
        margin-top: 5px;
    }
    .input-import{
        margin-top: 5px;
        input{
            width: 100%;
        }
        input::file-selector-button {
            font-weight: bold;
            color: $button;
            border: thin solid $button;
            border-radius: 3px;
        }
    }
    
    .form {
        width: 80%;
        margin: auto;
        margin-top: 10px;
        // background-color: #fff;
        padding: 1rem;
        border-radius: 15px;
        box-shadow: 0px 0px 5px 1px rgb(0 0 0 /20%);

        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 1rem;
        border-radius: .5rem;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
        color: #fff;
        label{
            font-size: 0.8rem;
            font-weight: 800;
        }
        form{
            margin-top: 10px;
            textarea{
                border-radius: 10px;
                outline: none;
                border: 1px solid $button;
                padding: 3px 8px;
                width: calc(100% - 1rem);
                margin-top: 4px;

                background: rgba(255, 255, 255, 0.05);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 1px solid rgba(255, 255, 255, 0.3);
                padding: 1rem 0.5rem;
                border-radius: .5rem;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.172);
                color: #fff;
            }
            
        }
    }
    .btn{
        text-align: center;
        button{
            width: 100%;
            margin-top: 10px;
            border: none;
            padding: 7px 10px;
            background-color: $button;
            color: #fff;
            font-weight: 600;
            border-radius: 0.3rem;
            font-size: 0.7rem;
            text-align: center;
            vertical-align: middle;
        }
    }
    .items:focus{
        box-shadow: 2px 1px 12px 3px rgba(48, 124, 248, 0.993) !important;
          }
}